@import "~antd/lib/style/themes/default.less";

.content {
    padding-right: 50px;

    .ant-table table {
        font-size: 20px;
        font-weight: 600;
    }
}
 

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 40px;

    & img {
        width: 150px;
        padding: 12px 12px;
    }

    & .caption {
        color: black;
        font-family: 'Noto Serif Georgian', sans-serif;
        display: flex;
        flex-direction: column;

        &__title {
            display: flex;
            align-items: baseline;        
        }
        &__subtitle {
            margin-top: -25px
        }

        & .capital {
            font-size: 80px;
        }

        & .words {
            font-size: 40px;
        }
    }
}


.reference {
    font-size: 18px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    & div {
        display: flex;
        justify-content: space-between;

    }
}

.particulars {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.content-section {
    font-size: 18px;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
}

.sideInformation {
    display: flex;
    flex-direction: column;
    align-items: center;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    top: 500px;
    left: -400px;
    width: 100vh;
    // width: 600px;
    position: absolute;
}

.button {
    padding-right: 50px;
}

@media screen and (max-width: 576px) {
    .content {
        padding-right: 0px;
    }

    .header {
        & img {
            width: 90px;
            padding: 12px 12px;
        }
    
        & .caption {
            color: black;
            font-family: 'Noto Serif Georgian', sans-serif;
            display: flex;
            flex-direction: column;
    
            &__title {
                display: flex;
                align-items: baseline;        
            }
            &__subtitle {
                margin-top: -8px;
                font-size: 8.5px;
            }
    
            & .capital {
                font-size: 40px;
            }
    
            & .words {
                font-size: 20px;
            }
        }
    }

    .reference {
        font-size: 12px;
    }
    
}

@media print {
    header {
        display: none !important;
    }
    .ant-breadcrumb {
        display: none;
    }

    .slider{
        display: none;
    }

    .content {
        margin: auto;
        padding-left: 100px;
        padding-right: 0px;
        height: 100vh;
    }

    .sideInformation {
        left: -500px;
    }

    .button {
        display: none;
    }

    .backButton {
        display: none;
    }
}

.ant-page-header-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}