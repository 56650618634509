.monthly-case-wrapper {
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.title-area {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;


    &__number {
        display: flex;
        align-items: center;
        line-height: 1.1;

        span {
            font-size: 28px;
            margin-right: 5px;
        }
    }
}

@media screen and (max-width: 576px) {
    .monthly-case-wrapper {
        height: 192px;
    }

    .title-area {
        span {
            font-size: 16px !important;
            line-height: 1.2;
        }

        &__number {

            span {
                font-size: 30px !important;
                line-height: 0.9;
            }
        }
    }
}